@charset "utf-8";

// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #424144;
$background-color: #fdfdfd;
$border-color:     #8f8f8f;
$border-light-color:     #dddddd;
$loew-yellow:      #fdd805;
$loew-yellow-new:  #ffe700;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;

$bp--small: 480px;
$bp--medium: 600px;
$bp--large: 960px;


@mixin mq($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}
// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
