body {
  width: 100%;
  height: 100%;
}

.slideout-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 256px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translateX(-256px);
  transition: transform 0.3s ease-in-out;
  will-change: transform;
}

.slideout-panel {
  position:relative;
  z-index: 1;
  will-change: transform;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
  transform: translateX(0px);
}

.slideout-menu {
  background-color: #1D1F20;
  background-image: linear-gradient(145deg, #1D1F20, #404348);
  z-index: 99;
}

.toggle-button, .close-button, .hamburger-button {
  font-size: 20px;
  color: $text-color;
  display: none;
  @include mq($bp--large) {
    display: inline-block;
  }
}

.slideout-menu a, .menu-header-title {
  color: #fff;
}

.slideout-menu a:hover {
  text-decoration: underline;
}

.menu-header {
  border-bottom: 1px solid #2a2d2f;
  padding: 20px 0 20px 20px;
}

.menu-header-title {
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 0;
}

.menu-section {
  margin: 25px 0;
}

.menu-section-title {
  text-transform: uppercase;
  color: #85888d;
  font-weight: 200;
  font-size: 13px;
  letter-spacing: 1px;
  padding: 0 20px;
  margin:0;
}

.menu-section-list {
  padding:0;
  margin: 10px 0;
  list-style:none;
}

.menu-section-list a {
  display: block;
  padding: 10px 20px;
}

.menu-section-list a:hover, .slideout-menu li.active, .slideout-menu li.active a {
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
  color: $loew-yellow-new;
}

.panel {
  text-align: center;
  padding-top: 5px;
  min-height: 100%;
}

/**
 * Header
 */
.panel-header {
  margin: 85px auto 55px;
}

.title {
  font-size: 3.2em;
  line-height: 1em;
  margin: 0 0 15px;
  color: #4B5;
  font-weight: 400;
  padding-top: 82px;
  background: url('../assets/mango-logo.png') no-repeat center top;
}

.subtitle {
  font-weight: 400;
  font-size: 1.3em;
  line-height: 1.2em;
  margin:0 0 45px 0;
  color: #767676;
}

/**
 * Action buttons
 */
.panel-actions {
  margin: 20px 0;
}

.btn {
  text-decoration: none;
  padding: 0px 14px;
  border-radius: 4px;
  line-height: 50px;
  display: inline-block;
  border: 1px solid #4B5;
  width: 35%;
  font-size: 1.2em;
}

.btn-download {
  margin-right: 5px;
  background-color: #4B5;
  color: #fff;
  font-weight: 600;
}

.btn-download:hover {
  background-color: #3da84b;
  border-color: #369643;
}

.btn-fork {
  margin-left: 5px;
  background-color: #fff;
}

.btn-fork:hover {
  background-color: rgba(0,0,0,.02);
}

/**
 * iPhone
 */
.iphone {
  background: url('../assets/iphone5.png') no-repeat;
  position: relative;
  margin: 0 auto;
  width: 298px;
  height: 597px;
}

.iphone img,
.iphone video {
  position: absolute;
  top: 73px;
  left: 25px;
  height: 446px;
  max-width: 100%;
}

.iphone video {
  display: none;
}

/**
 * hamburger
 */
.toggle-button {
  border: none;
  position: absolute;
  top: 12px;
  left: 12px;
  outline:none;
}

.tooltip {
  font-size: 20px;
  line-height: 19px;
  display: inline-block;
  color: #fff;
  padding: 10px 45px 10px 20px;
  border-radius: 4px;
  position: relative;
  left: 50px;
}

.tooltip:before {
  content: '';
  display:inline-block;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #4B5;
  position: absolute;
  top: 50%;
  left: -5px;
  margin-top: -6px;
}






/* Animation
---------------------------------- */
@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform:translate3d(0, 0, 0);
    transform:translate3d(0, 0, 0);
  }

  50% {
    -webkit-transform:translate3d(-10px, 0, 0);
    transform:translate3d(-10px, 0, 0);
  }
}

@keyframes shake {
  0%, 100% {
    -webkit-transform:translate3d(0, 0, 0);
    transform:translate3d(0, 0, 0);
  }

  50% {
    -webkit-transform:translate3d(-10px, 0, 0);
    transform:translate3d(-10px, 0, 0);
  }
}

.shake {
  -webkit-animation-name:shake;
  animation-name:shake;
  -webkit-animation-duration:2s;
  animation-duration:2s;
  -webkit-animation-iteration-count: 4;
  animation-iteration-count: 4;
}